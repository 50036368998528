(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("styled"), require("ReactDOM"), require("dotnetify"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "styled", "ReactDOM", "dotnetify"], factory);
	else if(typeof exports === 'object')
		exports["dotnetify-elements"] = factory(require("React"), require("styled"), require("ReactDOM"), require("dotnetify"));
	else
		root["dotNetifyElements"] = factory(root["React"], root["styled"], root["ReactDOM"], root["dotnetify"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__56__) {
return 